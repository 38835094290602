
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
import CheckoutModule from '../../store/modules/CheckoutModule';
// @ts-ignore
import {en, da, sv, nbNO, fi, de, fr, es, it} from 'vuejs-datepicker/dist/locale';
import { DisabledDatesModel } from '../../models/Checkout';

@Component({
    name: 'CbxDatePicker',
    components: {
        Datepicker,
    },
})
export default class CbxDatePicker extends Vue {

    @Prop({ required: true, default: new Date() }) private value: Date|null;
    @Prop({ type: Boolean, required: false, default: false }) private inline: boolean;
    @Prop({ type: Object, required: false }) private disabledDates: DisabledDatesModel;
    @Prop({ type: String, required: false }) private placeholder: string;

    get datepickerLanguage() {
        switch (this.$i18n.locale) {
            case 'da-DK':
                return da;
            case 'sv-SE':
                return sv;
            case 'nb-NO':
            case 'nn-NO':
                return nbNO;
            case 'fi-FI':
                return fi;
            case 'de-DE':
                return de;
            case 'fr-FR':
                return fr;
            case 'es-ES':
                return es;
            case 'it-IT':
                return it;
            default:
                return en;
        }
    }

    get model() {
        return this.value;
    }

    set model(input: Date) {
        this.$emit('input', input);
    }

    get mondayFirst(): boolean {
        return this.datepickerLanguage !== en;
    }

    get disabledDatesValues() {
        if (this.disabledDates) {
            return {
                to: this.disabledDates.mindate,
                from: this.disabledDates.maxdate,
                days: this.disabledDates.disabledweekdays,
                dates: this.disabledDates.disableddates,
            }
        }
        return {};
    }

    private async created() {
        CheckoutModule.GET_DELIVERY_DATE();
        CheckoutModule.GET_DISABLED_DELIVERY_DATES();
    }
}
