
import { Component, Vue, Prop } from 'vue-property-decorator';
import CbxDatePicker from './utils/CbxDatePicker.vue';
import { DisabledDatesModel } from '@/models/Checkout';
import { AddOrUpdateCartEditModel, BouquetDelivery } from '@/models/Cart';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import CartModule from '@/store/modules/CartModule';
import LoadingModule from '@/store/modules/LoadingModule';

@Component({
    name: 'AddToCartButtonBouquetDelivery',
    components: {
        CbxDatePicker,
    },
})
export default class AddToCartButtonBouquetDelivery extends Vue {
    @Prop({ type: String, required: false, default: '' }) private sku: string;
    @Prop({ type: String, required: false, default: '' }) private variantid: string;
    @Prop({ type: String, required: false, default: '' }) private unit: string;
    @Prop({ type: Number, required: false, default: 1 }) private quantity: number;
    @Prop({ type: Number, required: false, default: 1 }) private colli: number;
    @Prop({ type: Array, required: false }) private addonProducts: string[];
    @Prop({ type: String, required: false, default: 'default' }) private size: string;
    @Prop({ type: Boolean, required: false, default: false }) private textCustomizable: boolean;

    private modals: any = {
        bouquetDelivery: false,
    };
    private bouquetDelivery: BouquetDelivery = null;
    private disabledDeliveryDates: DisabledDatesModel = {
        mindate: new Date(),
        maxdate: null,
        disableddates: [],
        disabledweekdays: [0],
    };

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get isLoading(): boolean {
        return LoadingModule.IS_LOADING;
    }

    get sizeClasses(): any {
        const retval: any = {
            input: '',
            button: '',
            iconRatio: '.8',
        };
        if (this.size !== 'medium') {
            retval.input = `uk-form-${this.size}`;
            retval.button = `uk-button-${this.size}`;
        }
        if (this.size === 'small') {
            retval.iconRatio = '.6';
        }
        if (this.size === 'large') {
            retval.iconRatio = '1.1';
        }

        return retval;
    }

    private modalShow(section: string) {
        this.modals[section] = true;
    }

    private modalHide(section: string) {
        this.modals[section] = false;

        if (section === 'bouquetDelivery') {
            this.initBouquetDelivery();
        }
    }

    private async addToCart() {
        const payload: AddOrUpdateCartEditModel = {
            productid: this.sku,
            quantity: this.quantity,
            variantid: this.variantid + '-' + new Date().getTime(),
            addonproductids: this.addonProducts ?? [],
            extrafields: {
                bouquetdelivery: this.bouquetDelivery,
            },
        };

        await CartModule.ADD_TO_CART(payload);

        this.modalHide('bouquetDelivery');

        if (CbxVariablesModule.VARIABLES.enableAddedToCartModal) {
            CartModule.SET_ADD_TO_CART_MODAL({
                show: true,
                productId: payload.productid,
                variantId: payload.variantid,
            });
        } else {
            CartModule.SET_IS_OPEN(true);
        }
    }

    private initBouquetDelivery() {
        let nextavailabledate = new Date(new Date().setDate(new Date().getDate() + 1));
        while (this.disabledDeliveryDates.disabledweekdays.some((wd) => wd == nextavailabledate.getDay())) {
            nextavailabledate = new Date(nextavailabledate.setDate(nextavailabledate.getDate() + 1));
        }

        this.bouquetDelivery = {
            text: '',
            deliverydate: nextavailabledate,
        };
    }

    private created() {
        this.initBouquetDelivery();
    }
}
